import { createSlice } from "@reduxjs/toolkit";

export const searchResultSlice = createSlice({
    name: "Search-result",
    initialState: {
        products: [],
        searchTerm: ''
    },
    reducers: {
        setProductsList: (state, action) => {
            state.products = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    }
});

export const {
  setProductsList,
  setSearchTerm
} = searchResultSlice.actions;

export const selectProducts = (state) => state.search.products;
export const selectSearchTerm = (state) => state.search.searchTerm;

export default searchResultSlice.reducer;
