import { createSlice } from "@reduxjs/toolkit";

// Utility Service
import { getWishListProductCookies, getWishListCalendarProductCookies } from '../utils/AuthService';

export const wishlistSlice = createSlice({
    name: "Wishlist",
    initialState: {
        wishlistProduct: getWishListProductCookies() || [],
        wishlistCalendarProduct: getWishListCalendarProductCookies() || [],
    },
    reducers: {
        setWishlistProduct: (state, action) => {
            state.wishlistProduct = action.payload;
        },
        setWishlistCalendarProduct: (state, action) => {
            state.wishlistCalendarProduct = action.payload;
        }
    }
});

export const {
    setWishlistProduct,
    setWishlistCalendarProduct
} = wishlistSlice.actions;

export const selectWishlistProduct = (state) => state.wishlist.wishlistProduct;
export const selectWishlistCalendarProduct = (state) => state.wishlist.wishlistCalendarProduct;

export default wishlistSlice.reducer;
