import { createSlice } from "@reduxjs/toolkit";

export const searchNewsSlice = createSlice({
    name: "Search-News",
    initialState: {
        searchTerm: ''
    },
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    }
});

export const {
  setSearchTerm
} = searchNewsSlice.actions;

export const selectSearchTerm = (state) => state.searchNews.searchTerm;

export default searchNewsSlice.reducer;
