import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './App.css';

import ScrollToTop from './components/ScrollToTop';

// Auth-Service
import PrivateRoute from './utils/PrivateRoute';

const SignIn = lazy(() => import('./pages/SignIn/SignIn'));
const Signup = lazy(() => import('./pages/SignUp/SignUp'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const Verification = lazy(() => import('./pages/Verification/Verification'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const FourZeroFour = lazy(() => import('./pages/404/404'));

const Homepage = lazy(() => import('./pages/Homepage/Homepage'));
const ProductList = lazy(() => import('./pages/ProductList/ProductList'));
const SingleProduct = lazy(() => import('./pages/SingleProduct/SingleProduct'));
const Bag = lazy(() => import('./pages/Bag/Bag'));
const Checkout = lazy(() => import('./pages/Checkout/Checkout'));
const Order = lazy(() => import('./pages/Order/Order'));
const ReleaseCalendar = lazy(() => import('./pages/ReleaseCalendar/ReleaseCalendar'));
const SingleCalendarProduct = lazy(() => import('./pages/SingleCalendarProduct/SingleCalendarProduct'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Wallet = lazy(() => import('./pages/Wallet/Wallet'));
const AddBank = lazy(() => import('./pages/AddBank/AddBank'));
const EditBank = lazy(() => import('./pages/EditBank/EditBank'));
const Transaction = lazy(() => import('./pages/Transaction/Transaction'));
const Raffle = lazy(() => import('./pages/Raffle/Raffle'));
const RaffleHistory = lazy(() => import('./pages/RaffleHistory/RaffleHistory'));
const WishList = lazy(() => import('./pages/WishList/WishList'));
const PurchaseHistory = lazy(() => import('./pages/PurchaseHistory/PurchaseHistory'));
const SinglePurchaseHistory = lazy(() => import('./pages/SinglePurchaseHistory/SinglePurchaseHistory'));
const AddressBook = lazy(() => import('./pages/AddressBook/AddressBook'));
const ChangePassword = lazy(() => import('./pages/ChangePassword/ChangePassword'));
const News = lazy(() => import('./pages/News/News'));
const SingleNews = lazy(() => import('./pages/SingleNews/SingleNews'));
const Lucky = lazy(() => import('./pages/Lucky/Lucky'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions/TermsAndConditions'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./pages/RefundPolicy/RefundPolicy'));
const PaymentMethods = lazy(() => import('./pages/PaymentMethods/PaymentMethods'));
const ShippingInfo = lazy(() => import('./pages/ShippingInfo/ShippingInfo'));

const App = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback={<div className='d-flex justify-content-center align-items-center overflow-hidden vw-100 vh-100'>
                <Spinner animation="border" />
            </div>}>
                <Routes>
                    {/* Authentication Route */}
                    <Route path="/login" element={<SignIn />} />
                    <Route path="/register" element={<Signup />} />
                    <Route path="/forget-password" element={<ForgotPassword />} />
                    <Route path="/otp-verify" element={<Verification />} />
                    <Route path="/reset-password" element={<ResetPassword />} />

                    {/* Home Page Route */}
                    <Route path="/" element={<Homepage />} />
                    {/* Product List Page Route */}
                    <Route path="/products" element={<ProductList />} />
                    {/* Single Product Page Route */}
                    <Route path="/product/:productID" element={<SingleProduct />} />
                    <Route path="/product" element={<SingleProduct />} />
                    {/* Release Calendar Page Route */}
                    <Route path="/release-calendar" element={<ReleaseCalendar />} />
                    {/* Single Calendar Product Page Route */}
                    <Route path="/calendar-product/:productID" element={<SingleCalendarProduct />} />

                    {/* Private Route */}
                    <Route element={<PrivateRoute />}>
                        {/* Profile Page Route */}
                        <Route path="/profile" element={<Profile />} />
                        {/* Wallet Page Route */}
                        <Route path="/wallet" element={<Wallet />} />
                        {/* Add Bank Page Route */}
                        <Route path="/add-bank" element={<AddBank />} />
                        {/* Edit Bank Page Route */}
                        <Route path="/edit-bank/:bankAccountId" element={<EditBank />} />
                        {/* Transaction History Page Route */}
                        <Route path="/transaction" element={<Transaction />} />
                        {/* Raffle Page Route */}
                        <Route path="/raffle" element={<Raffle />} />
                        <Route path="/raffleJoin">
                            <Route path=":status/:joinId/:error" element={<Raffle />} />
                            <Route path=":status/:joinId" element={<Raffle />} />
                            <Route path=":status" element={<Raffle />} />
                        </Route>
                        {/* Raffle History Page Route */}
                        <Route path="/raffle-history" element={<RaffleHistory />} />
                        {/* Wishlist Page Route */}
                        <Route path="/wishlist" element={<WishList />} />
                        {/* Purchase History Page Route */}
                        <Route path="/purchase-history" element={<PurchaseHistory />} />
                        {/* Single Purchase History Page Route */}
                        <Route path="/single-purchase-history/:orderID" element={<SinglePurchaseHistory />} />
                        {/* Address Book Page Route */}
                        <Route path="/address-book" element={<AddressBook />} />
                        {/* Password Change Page Route */}
                        <Route path="/change-password" element={<ChangePassword />} />
                        {/* Bag Page Route */}
                        <Route path="/bag" element={<Bag />} />
                        {/* Checkout Page Route */}
                        <Route path="/checkout" element={<Checkout />} />
                        {/* Order Model Route */}
                        <Route path="/order">
                            <Route path=":status/:orderId/:error" element={<Order />} />
                            <Route path=":status/:orderId" element={<Order />} />
                        </Route>
                    </Route>

                    {/* News Page Route */}
                    <Route path="/news" element={<News />} />
                    {/* Single News Page Route */}
                    <Route path="/news/:newsID" element={<SingleNews />} />
                    {/* Lucky Page Route */}
                    <Route path="/lucky" element={<Lucky />} />
                    {/* About Us Page Route */}
                    <Route path="/about-us" element={<AboutUs />} />
                    {/* Terms & Conditions Page Route */}
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    {/* Contact Us Page Route */}
                    <Route path="/contact-us" element={<ContactUs />} />
                    {/* Privacy Policy Page Route */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    {/* Refund Policy Page Route */}
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                    {/* Payment Methods Page Route */}
                    <Route path="/payment-methods" element={<PaymentMethods />} />
                    {/* Shipping Info Page Route */}
                    <Route path="/shipping-info" element={<ShippingInfo />} />
                    {/* 404 Page Route */}
                    <Route path="/404" element={<FourZeroFour />} />
                    {/* Error Page Route */}
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
